import { FormType } from "@/components/forms/";

// These remaining items are unrelated to contact form submission and are used in other areas.
export const TRACKING_PAYLOADS = {
	[FormType.VALUATION]: {
		"Event Category": "conversion",
		"Event Action": "valuation_submission",
		"Event Label": "info",
		event: "valuationForm",
	},
	[FormType.VALUATION_FUNNEL]: [
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_0_start",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_1_propertytype",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_2_propertydata",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_3_reason",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_1.5_propertycondition",
			"Event Label": "",
			event: "valuationFunnel",
		},
	],
	[FormType.POSTCODE_VALUATION_FUNNEL]: {
		submitPostcode: {
			"Event Category": "We Know Your Buyer",
			"Event Action": "we_know_your_buyer_1_start",
			event: "postcodeValuationFunnel",
		},
		getInTouch: {
			"Event Category": "We Know Your Buyer",
			"Event Action": "we_know_your_buyer_2_buyers",
			event: "postcodeValuationFunnel",
		},
		submitPersonalInfo: {
			"Event Category": "We Know Your Buyer",
			"Event Action": "we_know_your_buyer_3_contact_details",
			event: "postcodeValuationFunnel",
		},
	},
};
